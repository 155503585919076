import {
  Box,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { convert } from "html-to-text";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
}));

const myTypes = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const JobInfo = ({ isDrawerOpen }) => {
  const baseURL = process.env.REACT_APP_BASEURL;
  const { id } = useParams();
  const getToken = useSelector((state) => state.user.token);
  const [jobinfo, setJoninfo] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}/job-info/${id}`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        setSkillsData(response.data.message.skills);
        setJoninfo(response.data.message.jobInfoResult[0]);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          Cookies.remove("_secure_ARJ_");
          window.location.href = "/login";
        }
      });
  }, [isDrawerOpen]);

  const date = new Date(jobinfo?.date_created).toLocaleDateString();
  let skills = jobinfo?.skills;

  // There is also an alias to `convert` called `htmlToText`.

  const options = {
    wordwrap: 130,
    // ...
  };
  const text = convert(
    jobinfo?.description ? jobinfo?.description : "NA",
    options
  );

  useEffect(() => {
    axios
      .post(
        `${baseURL}/question-list-job/${id}`,
        { applyFilter: 0 },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response?.status === 401) {
          localStorage.clear();
          Cookies.remove("_secure_ARJ_");
          window.location.href = "/login";
        }
        setQuestions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching questions for job:", error);
      });
  }, []);

  return (
    <div>
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Description</Typography>
          <Typography variant="body1" maxWidth="lg" sx={{ mt: 1.5, mb: 3 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: jobinfo?.description ? jobinfo?.description : "NA",
              }}
            />
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Skills & Other Details
          </Typography>
          <Typography variant="body1">
            <Typography
              variant="body1"
              maxWidth={"lg"}
              color={"rgba(0, 0, 0, 0.87)"}
              sx={{ my: 2 }}
              gutterBottom
            >
              Skill(s)
            </Typography>
            <Stack spacing={1}>
              <Stack
                direction="row"
                className="fw-500"
                flexWrap={"wrap"}
                gap={2}
                sx={{ textTransform: "capitalize" }}
              >
                {skillsData &&
                  skillsData?.map((item) => {
                    return (
                      <Chip
                        label={item?.skill}
                        variant="filled"
                        sx={{ backgroundColor: "rgba(0, 0, 0, 0.87))" }}
                      />
                    );
                  })}
              </Stack>
            </Stack>
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }} maxWidth="lg">
          <Grid container spacing={3} alignItems="stretch">
            {/* Row 1 */}
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Job Type
                </Typography>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  {myTypes[jobinfo?.type] ? myTypes[jobinfo?.type] : "NA"}
                </Typography>
              </Item>
            </Grid>
            {/* <Grid item xs={4}> */}
            {/* <Item>
                <Typography variant="body1" gutterBottom>
                  Job Title
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ wordBreak: "break-word", textTransform: "capitalize" }}
                  gutterBottom
                >
                  {jobinfo?.title ? jobinfo?.title : "NA"}
                </Typography>
              </Item> */}
            {/* </Grid> */}
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Category
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                  fontWeight="bold"
                  gutterBottom
                >
                  {jobinfo?.role ? jobinfo?.role : "NA"}
                </Typography>
              </Item>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid container spacing={3} alignItems="stretch" mt={2}>
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Experience
                </Typography>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  {jobinfo?.experience || "Any"}
                </Typography>
              </Item>
            </Grid>
            {/* <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Location
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                  fontWeight="bold"
                  gutterBottom
                >
                  {jobinfo?.city ? jobinfo?.city : "NA"},{" "}
                  {jobinfo?.state ? jobinfo?.state : "NA"}
                </Typography>
              </Item>
            </Grid> */}
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Created On
                </Typography>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  {date ? date : "NA"}
                </Typography>
              </Item>
            </Grid>
          </Grid>

          {/* Row 3 */}
          <Grid container spacing={3} alignItems="stretch" mt={2}>
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Recruiter Name
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                  fontWeight="bold"
                  gutterBottom
                >
                  {jobinfo?.recruiter_name ? jobinfo?.recruiter_name : "NA"}
                </Typography>
              </Item>
            </Grid>
            {/* <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Work Mode
                </Typography>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  {jobinfo?.workmode ? jobinfo?.workmode : "NA"}
                </Typography>
              </Item>
            </Grid> */}
            <Grid item xs={4}>
              <Item>
                <Typography variant="body1" gutterBottom>
                  Openings
                </Typography>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  {jobinfo?.openings ? jobinfo?.openings : "NA"}
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Internal Notes
          </Typography>
          <Typography
            variant="body1"
            maxWidth={"lg"}
            sx={{ mt: 1.5, mb: 3, wordBreak: "break-word" }}
          >
            {jobinfo?.notes ? jobinfo?.notes : "NA"}
          </Typography>
        </Box> */}
      </Container>
    </div>
  );
};

export default JobInfo;
