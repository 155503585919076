import * as React from "react";
import Menu from "@mui/material/Menu";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorRadio = styled(Radio)(({ theme, value }) => {
  const getColor = () => {
    switch (value) {
      case "1":
        return theme.palette.success.main; // Green color
      case "0":
        return theme.palette.warning.main; // Orange color
      case "-1":
        return theme.palette.error.main; // Red color
      default:
        return theme.palette.text.primary;
    }
  };
  return {
    color: getColor(),
    "&.Mui-checked": {
      color: getColor(),
    },
  };
});

export default function CompanyFilter({
  open,
  handleClose,
  anchorEl,
  radioBtn,
  setRadioBtn,
  handleFilterData,
  handleResetData,
  setSelectedData,
  selectedData,
}) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "190px",
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "190px",
      marginLeft: "20px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
      padding: 0,
    }),
  };

  const options = [
    { value: "Active", label: "Active" },
    { value: "Canceled", label: "Canceled" },
    { value: "Full", label: "Full" },
    { value: "Closed", label: "Closed" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "OnHold", label: "On Hold" },
    { value: "Lead", label: "Prospective / Lead" },
  ];

  return (
    <div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          width: 260,
        }}
      >
        {" "}
        <div>
          <div
            style={{
              marginLeft: "20px",
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Status
          </div>
          <Select
            styles={customStyles}
            closeMenuOnSelect={false}
            isMulti
            options={options}
            value={selectedData}
            onChange={(selectedOptions) => setSelectedData(selectedOptions)}
            isSearchable={false}
          />
        </div>
        <div style={{ paddingTop: "2rem" }}>
          <div
            style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "15px" }}
          >
            Is Approved
          </div>
          <FormControl sx={{ marginLeft: "20px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radioBtn}
              onChange={(e) => setRadioBtn(e.target.value)}
              sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
            >
              <FormControlLabel
                value={"1"}
                control={<ColorRadio value={"1"} />}
                label="Approved"
              />
              <FormControlLabel
                value={"0"}
                control={<ColorRadio value={"0"} />}
                label="Pending"
              />
              <FormControlLabel
                value={"-1"}
                control={<ColorRadio value={"-1"} />}
                label="Rejected"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
            gap: "10px",
            marginTop: "0.5rem",
            marginBottom: "0.8rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ height: "32px" }}
            onClick={() => {
              handleResetData();
              handleClose();
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{ height: "32px" }}
            onClick={() => {
              handleFilterData();
              handleClose();
            }}
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
}
