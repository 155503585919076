import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { styled } from "@mui/material/styles";
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Drawer,
  Divider,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Autocomplete,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ActiveJobs from "../components/activeJobs";
import Createjobdrawer from "../components/createjobdrawer";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JobOrders = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(() => {
    // Check if there is a stored active tab in localStorage
    const storedValue = localStorage.getItem("jobOrdersTab");
    return storedValue ? parseInt(storedValue, 10) : 0;
  });
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Store the active tab in localStorage when it changes
    localStorage.setItem("jobOrdersTab", value.toString());
  }, [value]);

  const handlepostjob = () => {
    console.log("himanshu");
    navigate("/Postjob");
  };

  return (
    <div>
      <Header />
      <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2, md: 3, lg: 8 } }}>
        <Box sx={{ flexGrow: 1, mt: 4 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item xs={6}>
              <Item>
                <Typography variant="h3" align="left" component="h1">
                  Job Orders
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="All Jobs"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(0)}
              />
              {/* <Tab
                label="Inactive"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(1)}
              /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ActiveJobs
              isActive={1}
              isSuccess={isSuccess}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={1}>
            <ActiveJobs isActive={0} isSuccess={isSuccess} />
          </CustomTabPanel> */}
        </Box>
      </Container>
    </div>
  );
};

export default JobOrders;
