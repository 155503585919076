import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const AttachmentCell = ({ attachment, name }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderAttachment = () => {
    if (!attachment) {
      return (
        <Typography
          sx={{
            fontSize: "1rem",
            fontStyle: "italic",
            color: "gray",
          }}
        >
          No attachment available
        </Typography>
      );
    }
    if (attachment.endsWith(".pdf")) {
      return (
        <iframe
          src={attachment}
          width="100%"
          height="600px"
          style={{
            border: "none",
            marginTop: "10px",
          }}
          title="PDF Viewer"
        ></iframe>
      );
    }
    if (attachment.endsWith(".docx")) {
      const docViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        attachment
      )}`;
      return (
        <iframe
          src={docViewerUrl}
          width="100%"
          height="100%"
          style={{
            border: "none",
            marginTop: "10px",
            height: "600px",
          }}
          title="Document Viewer"
        ></iframe>
      );
    }

    return (
      <Typography
        sx={{
          fontSize: "1rem",
          fontStyle: "italic",
          color: "gray",
        }}
      >
        Unable to preview the attachment. Please download the file to view it.
      </Typography>
    );
  };

  return (
    <>
      {attachment ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClickOpen}
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "0.875rem",
            fontWeight: "bold",
            padding: "5px 15px",
            border: "none",
          }}
        >
          <DescriptionOutlinedIcon />
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disabled
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "0.875rem",
            fontWeight: "bold",
            padding: "5px 15px",
            border: "none",
          }}
        >
          <DescriptionOutlinedIcon />
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "12px",
            padding: "20px",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {name}
        </DialogTitle>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "0.875rem",
            margin: "0 auto",
            display: "block",
          }}
          component="a"
          href={attachment}
          download
        >
          Download
        </Button>

        <DialogContent
          sx={{
            marginTop: "20px",
            textAlign: "center",
            padding: 0,
          }}
        >
          {renderAttachment()}
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "0.875rem",
              padding: "5px 20px",
              backgroundColor: "#1976d2",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
