import {
  Alert,
  Box,
  Chip,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CandidateDetails from "./candidateDetails";
import _debounce from "lodash/debounce";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";
import { AttachmentCell } from "./AttachmemtBox";

const statusColors = {
  Applied: "primary",
  "Employer Applied": "primary",
  Shortlisted: "success",
  "Tech Evaluation": "warning",
  "Manager Evaluation": "info",
  "HR Round": "info",
  "Offer Released": "success",
  Withdrawn: "error",
  Rejected: "error",
};

const columns = [
  {
    field: "fullname",
    headerName: "Full Name",
    filterable: false,
    width: 250,
    renderCell: (params) => params.row.fullname || "NA",
  },
  {
    field: "email_id",
    headerName: "Email Id",
    filterable: false,
    width: 250,
    renderCell: (params) => params.row.email1 || "NA",
  },
  {
    field: "date_created",
    headerName: "Applied On",
    filterable: false,
    width: 250,
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString("en-US"),
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.row.status || "NA"}
        variant="outlined"
        color={statusColors[params.row.status] || "default"}
      />
    ),
  },
  {
    field: "attachment",
    headerName: "Action",
    width: 250,
    renderCell: (params) => (
      <AttachmentCell
        attachment={params.row.resume_url}
        name={params.row.fullname}
      />
    ),
  },
];

const Applicants = (props) => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const [rerenderKey, setRerenderKey] = useState(0);
  const [searchTermPrev, setSearchTermPrev] = useState("");
  const [queryOptions, setQueryOptions] = useState({});
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  const [showLoader, setShowLoader] = useState(false);

  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const respFilterData = onGlobalFliterFunc(
      filterModel,
      `and concat(u.first_name, ' ', u.last_name)`
    );
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  useEffect(() => {
    setShowLoader(true);
    let payload = {
      jobId: id,
      isShortlisted: 0,
      filterCriteria: filterOptions?.filterCondition
        ? filterOptions?.filterCondition
        : "",
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .post(
        `${baseURL}/job-applicants/${
          searchTermPrev && searchTermPrev !== searchTerm
            ? 1
            : paginationModel.page + 1
        }${searchTerm ? "?search=" + searchTerm : ""}`,
        payload,
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const filteredApplicants = response.data.message.data.filter(
          (applicant) => applicant.id !== null
        );
        setApplicantsdata(filteredApplicants);
        setShowLoader(false);
        setSearchTermPrev(searchTerm);
        setTotalCount(response.data.message.page);
      });
  }, [
    paginationModel,
    queryOptions,
    searchTerm,
    isApplicanOpen,
    filterOptions,
  ]);

  const HandleRowClick = (params, event) => {
    setSelectedRowData(params.row);
    setApplicanOpen(false);
  };

  const filteredApplicants = applicants.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSelection = (params, event) => {
    const rowData = filteredApplicants.filter((e) => params.includes(e.id));
    const newdata = rowData.map((e) => {
      return {
        id: e.id,
        date_modified: e.date_modified,
      };
    });
  };

  const handleOnClose = () => {
    setApplicanOpen(false);
  };

  return (
    <div>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ width: "25ch" }}
          type="search"
          placeholder="Search..."
          id="standard-search"
          variant="standard"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
        }}
        keepNon
        rows={filteredApplicants}
        autoHeight
        columns={columns}
        key={rerenderKey}
        loading={showLoader}
        paginationMode="server"
        getRowId={(row) => row.id}
        onRowClick={HandleRowClick}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[18]}
        rowCount={totalCount}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={_debounce(onFilterChange, 500)}
        isRowSelectable={(params) => {
          if (
            params.row.isVerified === 0 ||
            params.row.status === "Withdrawn"
          ) {
            return false;
          }
          if (params.row.isVerified === 1) return true;
        }}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick
        className="data-grid-row-hover"
      />
      <CandidateDetails
        isOpen={isApplicanOpen}
        onClose={handleOnClose}
        setApplicanOpen={setApplicanOpen}
        setRerenderKey={setRerenderKey}
        selectedRowData={selectedRowData}
        candidatedrawerflag={true}
        Shortlisted={true}
        issearchnavbar={true}
        questionrequired={props.questionrequired}
        responsehide={props.questionrequired ? true : false}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            "snackbarMessage" === "Candidate Status Changed Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {"snackbarMessage"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Applicants;
