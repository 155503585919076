import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";

// Function to generate random colors based on name
const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

// Function to get the first letter of a name
const stringAvatar = (name) => ({
  children: name.charAt(0).toUpperCase(),
  sx: {
    bgcolor: stringToColor(name),
  },
});

// Comment Component
const Comment = ({ name, date, text }) => {
  return (
    <Box display="flex" gap={2} mb={2}>
      {/* Avatar */}
      <Avatar {...stringAvatar(name)} />

      {/* Content */}
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {date}
        </Typography>
        <Typography variant="body2" mt={0.5}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

// Comment Section Component
const CommentSection = ({ id, refresh }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const getToken = useSelector((state) => state.user.token);
  const baseURL = process.env.REACT_APP_BASEURL;

  // Fetch comments from API
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/v1/get-activity`, {
          params: { id },
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        });

        const formattedComments = response.data.message.map((comment) => {
          const formattedDate = new Date(
            comment.date_modified
          ).toLocaleString();
          return {
            name: comment.name,
            text: comment.comment,
            date: formattedDate,
          };
        });

        setComments(formattedComments);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [refresh]);

  return (
    <Box p={2}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : comments.length === 0 ? (
        <Typography>No comments available.</Typography>
      ) : (
        comments.map((comment, index) => (
          <Comment
            key={index}
            name={comment.name}
            date={comment.date} // Use formatted date
            text={comment.text} // Use comment text
          />
        ))
      )}
    </Box>
  );
};

export default CommentSection;
