import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import "../custom.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CompanyFilter from "./CompanyFilter";

const myTypes = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const statusColors = {
  Active: "primary",
  "On Hold": "default",
  Canceled: "error",
  Full: "warning",
  Closed: "error",
};

// const baseURL = process.env.REACT_APP_BASEURL;
const columns = [
  { field: "id", headerName: "ID", width: 60 },
  {
    field: "title",
    headerName: "Job Title",
    width: 250,
    renderCell: (params) => params.row.title,
  },
  {
    field: "company",
    headerName: "Company",
    width: 150,
    renderCell: (params) =>
      params.row.company_name ? params.row.company_name : "NA",
  },
  {
    field: "type",
    headerName: "Job Type",
    width: 180,
    valueGetter: (params) => myTypes[params.value] || "N/A",
    renderCell: (params) => myTypes[params.row.type],
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.row.status || "NA"}
        variant="outlined"
        color={statusColors[params.row.status] || "default"}
      />
    ),
  },
  {
    align: "start",
    field: "date_created",
    headerName: "Created On",
    width: 150,
    typeof: "date",
    valueGetter: ({ value }) =>
      value ? new Date(value).toLocaleDateString("en-US") : "N/A",
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString(),
  },
  {
    field: "count",
    headerName: "No. of Applications",
    width: 150,
    renderCell: (params) => (params.row.count ? params.row.count : 0),
  },
  {
    field: "recruiter",
    headerName: "Recruiter",
    width: 150,
    renderCell: (params) =>
      params.row.recruiter_name ? params.row.recruiter_name : "NA",
  },
  {
    field: "isApproved",
    headerName: "Is Approved",
    width: 150,
    renderCell: (params) => (
      <Chip
        size="small"
        label={
          params.row.isApproved === 1
            ? "Approved"
            : params.row.isApproved === 0
            ? "Pending"
            : "Rejected"
        }
        variant="outlined"
        color={
          params.row.isApproved === 1
            ? "success"
            : params.row.isApproved === 0
            ? "warning"
            : "error"
        }
      />
    ),
  },
];

const ActiveJobs = ({ isActive, isSuccess }) => {
  const nav = useNavigate();
  const getToken = useSelector((state) => state.user.token);
  const [activejobs, setActiveJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [radioBtn, setRadioBtn] = useState("");
  const [filteredActiveJobs, setfilteredActiveJobs] = useState([]);
  const [selectedData, setSelectedData] = React.useState([]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterData = () => {
    let filterData = activejobs?.data
      ?.filter(
        (job) =>
          job?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
          job?.recruiter_name
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase())
      )
      .filter((job) => {
        const selectedStatuses = selectedData.map((item) => item.value);
        return (
          selectedStatuses.length === 0 || selectedStatuses.includes(job.status)
        );
      })
      .filter((job) => {
        return radioBtn === ""
          ? job
          : Number(job.isApproved) === Number(radioBtn);
      });

    setfilteredActiveJobs(filterData);
  };

  const handleResetData = () => {
    setRadioBtn("");
    setSelectedData([]);
    setfilteredActiveJobs(activejobs?.data);
  };

  const baseURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");
    axios
      .post(
        `${baseURL}/user/jobOrders`,
        { company_id, isActive },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        localStorage.removeItem("jobdetailsTab");
        setActiveJobs(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          Cookies.remove("_secure_ARJ_");
          window.location.href = "/login";
        }
        setIsLoading(false);
      });
  }, [isActive, isSuccess]);

  const HandleRowClick = (params, event) => {
    const data = {
      id: params.row.id,
      title: params.row.title,
      type: params.row.type,
      status: params.row.status,
      date_created: params.row.date_created,
      count: params.row.count,
      openapplication: params.row.open_count,
      isApproved: params.row.isApproved,
    };
    nav(`/jobOrders/${params.row.id}`, {
      data,
      state: { isApproved: params.row.isApproved },
    });
  };

  useEffect(() => {
    setfilteredActiveJobs(activejobs?.data);
  }, [activejobs]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              variant="standard"
              placeholder="Search Job Title or Recruiter Name"
              id="standard-search"
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: "20px", width: "25%" }}
            />
            <div>
              <Button
                onClick={handleClick}
                sx={{
                  color: `${
                    radioBtn !== "" || selectedData.length !== 0
                      ? "white"
                      : "#0F52BA"
                  }`,
                  height: "2.5rem",
                  border: "none !important",
                }}
                variant={`${
                  radioBtn !== "" || selectedData.length !== 0
                    ? "contained"
                    : "outlined"
                }`}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <FilterAltOutlinedIcon fontSize="large" />
              </Button>
              {open && (
                <CompanyFilter
                  open={open}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                  radioBtn={radioBtn}
                  setRadioBtn={setRadioBtn}
                  handleFilterData={handleFilterData}
                  handleResetData={handleResetData}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              )}
            </div>
          </div>
          <DataGrid
            rows={filteredActiveJobs}
            columns={columns}
            autoHeight
            initialState={{
              ...filteredActiveJobs?.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
            // slots={{
            //   toolbar: GridToolbar,
            // }}
            onRowClick={HandleRowClick}
            className="data-grid-row-hover"
          />
        </>
      )}
    </div>
  );
};

export default ActiveJobs;
