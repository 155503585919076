import React, { useEffect, useState } from "react";
import "./login.css";
import "../App.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { UseSelector, useDispatch } from "react-redux";
import { getRefreshToken, getTokenID } from "../redux/modules/userSlice";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASEURL;

const defaultTheme = createTheme();

const Login = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  // const passwordRegex = /^.{6,}$/;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // User is already logged in, redirect to another route
      nav("/jobOrders"); // Change the destination route accordingly
      window.history.pushState(null, "", "/jobOrders"); // Modify browser history
    }
  }, [nav]);

  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
      handleSnackbarOpen("Invalid email format");
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password Required");
      isValid = false;
      // handleSnackbarOpen("Password Required");
    } else {
      setPasswordError("");
    }

    if (isValid) {
      axios
        .post(`${baseURL}/user/login`, { email: email, password: password })
        .then((response) => {
          const allCookies = document.cookie;
          console.log("allCookiesallCookiesallCookies", allCookies);
          const cookiesArray = document.cookie.split("; ");
          const refreshTokenCookie = cookiesArray.find((cookie) =>
            cookie.startsWith("_secure_ref=")
          );
          Cookies.set("_secure_ARJ_", response.data.message.token, {
            expires: 5,
          });
          if (refreshTokenCookie) {
            const refreshTokenValue = refreshTokenCookie.split("=")[1];
            console.log("Refresh Token:", refreshTokenValue);
            dispatch(getRefreshToken(refreshTokenValue));
          } else {
            console.log("Refresh Token not found");
          }
          //dispatch(getTokenID(response.data.message.token));
          localStorage.setItem("token", response.data.message.token);
          localStorage.setItem("company_id", response.data.message.company_id);
          localStorage.setItem("email_id", response.data.message.user.name);
          localStorage.setItem("username", response.data.message.user.email);
          localStorage.setItem("logo", response.data.message.logo);
          handleSnackbarOpen("Login Successful");

          // Delay the navigation for a few seconds
          setTimeout(() => {
            nav("/jobOrders");
          }, 1000); // 2000 milliseconds (2 seconds)
        })
        .catch((err) => {
          setPasswordError("Invalid Credentials");
          handleSnackbarOpen("Invalid Credentials");
        });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/logo.png"
            className="LoginJobezeLogo"
            alt="Employer Portal"
          />
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20);",
          }}
        >
          <Typography component="h1" sx={{ my: 2 }} variant="h4">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              // margin="dense"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onFocus={(e) => setEmailError("")}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <TextField
                type={showPassword ? "text" : "password"}
                value={password}
                size="small"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => setPasswordError("")}
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!passwordError}
                helperText={passwordError ? passwordError : ""}
              />
            </FormControl>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs>
                <Link
                  href="/forgetpassword"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    letterSpacing: "1px",
                  }}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 1 }}
              // disabled={password.length <= 0}
            >
              Log In
            </Button>
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "Login Successful" ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
