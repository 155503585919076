import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Autocomplete,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Stack,
  Snackbar,
  OutlinedInput,
  InputAdornment,
  Alert,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormHelperText from "@mui/material/FormHelperText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import Screeningquestion from "./Screeningquestion";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiDialog-paper": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },

  [theme.breakpoints.between("sm", "md")]: {
    "& .MuiDialog-paper": {
      width: "80%",
    },
  },

  [theme.breakpoints.up("lg")]: {
    "& .MuiDialog-paper": {
      width: "100%",
    },
  },
}));

const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  placeholder: "Write the Description here...",
};

const myTypes = {
  Hire: "H",
  Contract: "C",
  "Contract to Hire": "C2H",
  Freelance: "F",
};

const myTypesReverse = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const Createjobdrawer = ({
  editFlag,
  jobId,
  setDrawerOpen,
  toggleDrawer,
  isEdit,
}) => {
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [jobopenings, setJobopenings] = useState("");
  const [duration, setJobduration] = useState("");
  const [rate, setRate] = useState("");
  const [salary, setSalary] = useState("");
  const [minexp, setMinexp] = useState("");
  const [maxexp, setMaxexp] = useState("");
  const [singleworkmode, setSingleworkmode] = useState("On-Site");
  const [category, setCategory] = useState([]);
  const [singleCategory, setSingleCategory] = useState("");
  const [jobType, setJobType] = useState("");
  const [workmode, setWorkmode] = useState([]);
  const [states, setStates] = useState([]);
  const [singlestate, setSingleState] = useState({});
  const [cities, setCities] = useState([]);
  const [singlecities, setsinglecities] = useState("");
  const [notes, setNotes] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const getToken = useSelector((state) => state.user.token);
  const [jobTitleError, setJobTitleError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [companyError, setcompanyError] = useState("");
  const [jobtypeError, setjobtypeError] = useState("");
  const [minexpError, setminexpError] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [skillsError, setskillError] = useState("");
  const [stateError, setstateError] = useState("");
  const [cityError, setcityError] = useState("");
  const [maxexpError, setMaxexpError] = useState("");
  const [equalityError, setEqualityError] = useState("");
  const [cityarr, setcityarr] = useState([]);
  const [shouldRerunEffect, setShouldRerunEffect] = useState(false);
  const [SkillsOption, setSkillsOption] = useState([]);
  const [Logo, setLogo] = useState("");
  const [datemodified, setDateModified] = useState("");
  const [disableflag, setDisableFlag] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState([]);
  const [emptyQuestionIndices, setEmptyQuestionIndices] = useState([]);
  const [newerror, setnewerror] = useState([]);
  const [company, setcompany] = useState("");
  const [companyoptions, setcompanyoptions] = useState([]);
  const [company_name, setcompany_name] = useState("");

  const handleJobTitleBlur = () => {
    if (jobtitle.length > 60) {
      setJobTitleError("Maximum 60 characters allowed");
    } else {
      setJobTitleError("");
    }
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (editFlag || shouldRerunEffect) {
      axios
        .get(`job-info/${jobId}`, {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setDisableFlag(response.data.message.hasAnyoneApplied);
          const data = response.data.message.jobInfoResult[0];

          axios
            .post(process.env.REACT_APP_JOBEZEURL + "/state-city/list", {
              isoCode: data.city.split("(")[1].split(")")[0],
            })
            .then((response) => {
              if (data.city) {
                setCities(response.data.message);
                setcityarr(response.data.message.map((e) => e.city));
              } else {
                setStates(response.data.message);
              }
            });

          let skillArray = data?.skills ? data?.skills : [];
          setSkills(skillArray);
          setEditorData(data.description);
          setJobtitle(data.title);
          setJobopenings(data.openings);
          setRate(data.rate);
          setSalary(data.salary);
          setMinexp(data.min_experience);
          setMaxexp(data.max_experience);
          setSingleworkmode(data.workmode);
          setSingleCategory(data.role);
          setcompany(data.company_id);
          setcompany_name(data.name);
          setJobType(myTypesReverse[data.type]);
          setSingleState(data.state);
          setNotes(data.notes);
          setJobduration(data.duration);
          setsinglecities(data.city);
          setLogo(data.logo);
          setIsChecked(data.cover_letter_required);
          setDateModified(data.date_modified);
        })
        .catch((err) => {});
    }
  }, [isEdit]);

  useEffect(() => {
    axios
      .get(`all-category`)
      .then((response) => {
        setCategory(response.data.message.category);
        setWorkmode(response.data.message.workmode);
      })
      .catch((err) => {
        console.log(err);
      });
    const payload = {};
    if (singlestate && states.length > 0) {
      const findIsoCode = states.filter((e) => e.name === singlestate);
      payload.isoCode = findIsoCode[0]?.isoCode;
    }
    axios
      .post(process.env.REACT_APP_JOBEZEURL + "/state-city/list", payload)
      .then((response) => {
        if (payload.isoCode) {
          setCities(response.data.message);
          setcityarr(response.data.message.map((e) => e.city));
        } else {
          setStates(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [singlestate, isEdit]);

  const handlejobtitle = (e) => {
    const { value } = e.target;
    let formattedValue = value;

    if (value.length > 0) {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (formattedValue.length > 60) {
      setJobTitleError("Maximum 60 characters allowed");
    } else {
      setJobTitleError("");
    }
    setJobtitle(formattedValue);
    setJobTitleError("");
  };

  const handlejobopenings = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value === "") {
      setJobopenings();
      return;
    }

    const intValue = parseInt(value, 10);

    if (intValue > 1000) {
      setJobopenings(e.target.value.slice(0, -1));
    } else {
      setJobopenings(intValue.toString());
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text");
    const sanitizedValue = pastedValue.replace(/\D/g, "");
    const intValue = parseInt(sanitizedValue, 10);
    const finalValue = intValue > 1000 ? "1000" : intValue.toString();
    setJobopenings(finalValue);
  };

  const handleduration = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 20) {
      setJobduration(inputValue);
    }
  };

  const handlerate = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setRate(limitedValue);
  };

  const handlesalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setSalary(limitedValue);
  };

  const handleminexp = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "0");
    if (
      value === "" ||
      (/^0$|^[1-9]\d*$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) <= 40)
    ) {
      setMinexp(value);
    }
  };

  const handlemaxexp = (e) => {
    const value = e.target.value;

    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 1 &&
        parseInt(value, 10) <= 40)
    ) {
      setMaxexp(value);
      if (parseInt(value, 10) < parseInt(minexp, 10)) {
        setMaxexpError(
          "Maximum experience cannot be less than minimum experience."
        );
      } else {
        setMaxexpError("");
      }
    } else {
      setMaxexpError("Please enter a valid number between 1 and 40.");
    }
  };

  const handleBlurMinExp = () => {
    if (parseInt(minexp, 10) === parseInt(maxexp, 10)) {
      setminexpError(
        "Minimum experience cannot be equal to maximum experience."
      );
    } else {
      setminexpError("");
    }
  };

  const handleBlurMaxExp = () => {
    if (parseInt(maxexp, 10) < parseInt(minexp, 10)) {
      setMaxexpError(
        "Maximum experience cannot be less than minimum experience."
      );
    } else if (parseInt(maxexp, 10) === parseInt(minexp, 10)) {
      setMaxexpError(
        "Maximum experience cannot be equal to minimum experience."
      );
    } else {
      setMaxexpError("");
    }
  };

  const singlehandleworkmode = (e) => {
    setSingleworkmode(e.target.value);
  };

  const handleCategory = (event) => {
    setSingleCategory(event.target.value);
    setCategoryError("");
  };

  const handlecompany = (event) => {
    let tmplogo = companyoptions.find(
      (option) => option.company_id === event.target.value
    )?.logo;
    setLogo(tmplogo);
    setcompany(event.target.value);
    setcompanyError("");
  };

  useEffect(() => {
    axios
      .get(`/get-companies`, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setcompanyoptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });
  }, [editFlag]);

  const handleJobType = (event) => {
    setJobType(event.target.value);
    if (
      event.target.value === "Contract" ||
      event.target.value === "Contract to Hire" ||
      event.target.value === "Freelance"
    ) {
      setSalary("");
    }
    if (event.target.value === "Hire") {
      setJobduration("");
    }
    setjobtypeError("");
  };

  const handleStates = (event) => {
    setSingleState(event.target.value);
    setsinglecities("");
    setstateError("");
  };
  const handleCities = (event) => {
    setsinglecities(event.target.value);
    setcityError("");
  };

  const handleSkillChange = (_, newValues) => {
    const labels = newValues?.map((item) =>
      typeof item === "object" ? item.label : item
    );

    if (labels.length > 0) {
      const uniqueSkills = [
        ...new Set([
          ...skills?.map((e) => e.trim().toLowerCase()),
          ...labels?.map((e) => e.trim().toLowerCase()),
        ]),
      ];
      setSkills(uniqueSkills);
    } else {
      setSkills(newValues);
    }
    setskillError("");
  };

  const availableOptions = SkillsOption.filter(
    (option) => !skills.includes(option.label.toLowerCase())
  );

  const renderTags = (value, getTagProps) => {
    return (
      value &&
      value.map((option, index) => (
        <Chip
          key={index}
          variant="filled"
          label={option}
          {...getTagProps({ index })}
          onDelete={() => {
            const updatedSkills = skills?.filter((skill) => skill !== option);
            setSkills(updatedSkills);
          }}
        />
      ))
    );
  };

  const handlenotes = (e) => {
    setNotes(e.target.value);
  };

  const handleOpenPost = () => {
    setnewerror(emptyQuestionIndices);

    // Check if jobtitle is empty
    if (jobtitle.trim() === "") {
      setJobTitleError("Job Title is required.");
      scrollToErrorField("jobtitle");
    } else {
      setJobTitleError(""); // Clear the error if the field is valid
    }

    if (singleCategory === "") {
      setCategoryError("Category is required.");
      scrollToErrorField("category");
    } else {
      setCategoryError(""); // Clear the category error if the field is valid
    }

    if (company === "") {
      setcompanyError("Company is required.");
    } else {
      setcompanyError("");
    }

    if (jobType === "") {
      setjobtypeError("Job Type is required");
      scrollToErrorField("jobType");
    } else {
      setjobtypeError("");
    }

    if (minexp === "") {
      setminexpError("Minimum Experience required.");
    } else {
      setminexpError(""); // Clear the error if the field is valid
    }

    if (editorData.trim() === "") {
      setdescriptionError("Description is required.");
    } else {
      setdescriptionError(""); // Clear the error if the field is valid
    }

    // if (skills?.length === 0) {
    //   setskillError("Skills are required");
    // } else {
    //   setskillError("");
    // }

    if (skills?.length === 0) {
      setskillError("Skills are required");
      scrollToErrorField("skills");
    } else if (skills.length < 5) {
      scrollToErrorField("skills");
      setskillError("Minimum 5 skills required");
    } else {
      setskillError("");
    }

    // console.log(
    //   " aa gayi cityyyyyyyyyyyyyyyyyyyyyyyyyyy",
    //   singlecities,
    //   typeof singlecities,
    //   cityarr.includes(singlecities)
    // );
    if (
      !singlestate ||
      (Object.keys(singlestate) && Object.keys(singlestate).length === 0)
    ) {
      setstateError("State is required");
      scrollToErrorField("state");
    } else {
      setstateError("");
    }

    if (!cityarr.includes(singlecities)) {
      setcityError("City is required");
      scrollToErrorField("city");
    } else {
      setcityError("");
    }

    if (
      jobtitle &&
      jobtitle.trim() !== "" &&
      singleCategory !== "" &&
      company !== "" &&
      jobType !== "" &&
      minexp !== "" &&
      editorData !== "" &&
      skills.length !== 0 &&
      skills.length >= 5 &&
      singlestate &&
      Object.keys(singlestate)?.length !== 0 &&
      singlecities !== "" &&
      emptyQuestionIndices.length === 0
    ) {
      setOpenPost(true);
    }
  };

  const scrollToErrorField = (fieldName) => {
    const errorFieldRef = errorFieldRefs[fieldName];
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const errorFieldRefs = {
    jobtitle: useRef(null),
    category: useRef(null),
    jobType: useRef(null),
    skills: useRef(null),
    state: useRef(null),
    city: useRef(null),
  };

  const hanldeSkillInputChange = _debounce((value) => {
    skillssearch(value);
  }, 415);

  const skillssearch = async (val) => {
    try {
      if (val) {
        const response = await axios.get(
          process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`
        );
        setSkillsOption(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handlePostJob = () => {
    axios
      .post(
        `create-job`,
        {
          category: singleCategory,
          job_title: jobtitle,
          job_type: myTypes[jobType],
          no_of_opening: jobopenings == "0" || !jobopenings ? 1 : jobopenings,
          duration: duration,
          rate: rate,
          salary: salary,
          min_exp: minexp ? Number(minexp) : null,
          max_exp: maxexp ? Number(maxexp) : null,
          workmode: singleworkmode,
          city: singlecities,
          state: singlestate,
          job_desc: editorData,
          skills: skills,
          internal_notes: notes,
          companyId: company,
          cover_letter_required: isChecked ? 1 : 0,
          questionsArray: formData,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setOpenSnackbar(true);
          setSnackbarMessage("Job Created Successfully");
          setTimeout(() => {
            navigate("/jobOrders");
          }, 1000);
          setIsChecked(false);
          handleClosePost();
          setDrawerOpen(false);
          setOpenSnackbar(true);
          setEditorData("");
          setJobtitle("");
          setJobopenings("");
          setSkills([]);
          setcompany("");
          setRate("");
          setSalary("");
          setMinexp("");
          setMaxexp("");
          setSingleCategory("");
          setJobType("");
          setSingleState([]);
          setNotes("");
          setJobduration("");
          setsinglecities("");
          setOpenPost(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditJob = () => {
    axios
      .post(
        `update-job`,
        {
          job_id: jobId,
          category: singleCategory,
          job_title: jobtitle,
          job_type: myTypes[jobType],
          no_of_opening: jobopenings == "0" || !jobopenings ? 1 : jobopenings,
          duration: duration,
          rate: rate,
          salary: salary,
          min_exp: minexp ? Number(minexp) : null,
          max_exp: maxexp ? Number(maxexp) : null,
          workmode: singleworkmode,
          city: singlecities,
          state: singlestate,
          job_desc: editorData,
          skills: skills,
          internal_notes: notes,
          cover_letter_required: isChecked ? 1 : 0,
          original_date_modified: datemodified,
          questionsArray: formData,
          companyId: company,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setOpenSnackbar(true);
          setSnackbarMessage("Job Edited Successfully");
          setTimeout(() => {
            navigate("/jobOrders");
          }, 1000);
          setOpenPost(false);
        }
      })
      .catch((err) => {
        console.log("err", err.response.data.error.error);
        setSnackbarMessage(err.response.data.error.error);
      });
  };

  const handleClosePost = () => {
    setOpenPost(false);
  };

  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
    setdescriptionError("");
  };

  const handleCancelData = () => {
    setSingleCategory("");
    setCategoryError("");
    setcompany("");
    setcompanyError("");
    setJobtitle("");
    setJobTitleError("");
    setJobType("");
    setjobtypeError("");
    setJobduration("");
    setJobopenings("");
    setRate("");
    setskillError("");
    setMaxexp("");
    setMinexp("");
    setminexpError("");
    setSingleState("");
    setstateError("");
    setsinglecities("");
    setcityError("");
    setSalary("");
    setSkills("");
    setEditorData("");
    setdescriptionError("");
    navigate("/jobOrders");
    setShouldRerunEffect((prev) => !prev);
    setShouldRerunEffect((prev) => !prev);
  };

  useEffect(() => {
    const company_name = companyoptions?.filter(
      (data) => data.company_id == company
    )[0]?.name;
    setcompany_name(company_name);  
  }, [company]);

  return (
    <div>
      <Box width={"100%"}>
        <Box>
          <Typography
            sx={{
              p: { xs: 1, sm: 2, md: 2, lg: 2 },
            }}
          >
            <Typography
              variant="h5"
              component="div"
              ref={errorFieldRefs.category}
            >
              {editFlag ? " Edit Job " : "Create a Job"}
            </Typography>
          </Typography>
          <Divider />
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              maxWidth={1000}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                py: { xs: 1, sm: 2, md: 2, lg: 2 },
                px: { xs: 1, sm: 2, md: 2, lg: 2 },
              }}
            >
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  p: 0,
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={categoryError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    Category{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singleCategory}
                    size="small"
                    label="category"
                    onChange={handleCategory}
                    ref={errorFieldRefs.jobtitle}
                  >
                    {category &&
                      category?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {categoryError ? (
                    <FormHelperText>{categoryError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Typography>
              <FormControl
                size="small"
                fullWidth
                error={companyError ? true : false}
              >
                <InputLabel size="small" id="demo-simple-select-label">
                  Company
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={company}
                  size="small"
                  label="Company"
                  onChange={handlecompany}
                >
                  {companyoptions.map((options) => (
                    <MenuItem className="fw-bold" value={options.company_id}>
                      {options.name}
                    </MenuItem>
                  ))}
                </Select>
                {companyError ? (
                  <FormHelperText>{companyError}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                fullWidth
                label="Job Title"
                variant="outlined"
                value={jobtitle}
                error={jobTitleError !== ""}
                helperText={jobTitleError}
                onChange={handlejobtitle}
                onBlur={handleJobTitleBlur}
              />
              <Typography
                variant="body2"
                component="div"
                sx={{ display: "flex", gap: { xs: 1, sm: 2, md: 2, lg: 2 } }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={jobtypeError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    Job Type{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobType}
                    size="small"
                    label="jobType"
                    onChange={handleJobType}
                    ref={errorFieldRefs.jobType}
                  >
                    <MenuItem value={"Hire"}>Hire</MenuItem>
                    <MenuItem value={"Contract"}>Contract</MenuItem>
                    <MenuItem value={"Contract to Hire"}>
                      Contract to Hire
                    </MenuItem>
                    <MenuItem value={"Freelance"}>Freelance</MenuItem>
                  </Select>
                  {jobtypeError && (
                    <FormHelperText>{jobtypeError}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  size="small"
                  label="No. of Openings"
                  variant="outlined"
                  fullWidth
                  value={jobopenings}
                  onChange={handlejobopenings}
                  onPaste={handlePaste}
                />
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ display: "flex", gap: { xs: 1, sm: 2, md: 2, lg: 2 } }}
              >
                <TextField
                  id="duration"
                  size="small"
                  fullWidth
                  label="Duration"
                  variant="outlined"
                  value={duration}
                  onChange={handleduration}
                  disabled={jobType === "Hire" ? true : false}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Rate/Hour
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Rate/Hour"
                    size="small"
                    variant="outlined"
                    value={rate}
                    onChange={handlerate}
                  />
                </FormControl>
              </Typography>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Salary/Annum
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Salary/Annum"
                  value={salary}
                  size="small"
                  // type="number"
                  onChange={handlesalary}
                  disabled={jobType === "Hire" ? false : true}
                  // inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                />
              </FormControl>

              <Typography variant="h6" component="div">
                Experience (Years)
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <TextField
                  id="minExp"
                  size="small"
                  fullWidth
                  label="Minimum Experience"
                  variant="outlined"
                  value={minexp}
                  error={minexpError !== ""}
                  helperText={minexpError}
                  onChange={handleminexp}
                  onBlur={handleBlurMinExp}
                  onFocus={(e) => setminexpError("")}
                />{" "}
                -
                <TextField
                  id="maxExp"
                  size="small"
                  fullWidth
                  label="Maximum Experience"
                  variant="outlined"
                  value={maxexp}
                  error={maxexpError !== "" || equalityError !== ""}
                  helperText={maxexpError || equalityError}
                  onChange={handlemaxexp}
                  onBlur={handleBlurMaxExp}
                  onFocus={(e) => setMaxexpError("")}
                />
              </Typography>
              <Typography variant="h6" component="div">
                Work Mode
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={singleworkmode}
                  name="radio-buttons-group"
                  onChange={singlehandleworkmode}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: 1, sm: 2, md: 3, lg: 3 },
                    }}
                  >
                    {workmode &&
                      workmode?.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            sx={{ m: 0, p: 0 }}
                            value={item?.name}
                            control={<Radio />}
                            label={item?.name}
                          />
                        );
                      })}
                  </Typography>
                </RadioGroup>
              </FormControl>
              <Typography variant="h6" component="div">
                Location
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  p: 0,
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={stateError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singlestate}
                    size="small"
                    label="State"
                    onChange={handleStates}
                    ref={errorFieldRefs["state"] || null}
                  >
                    {states?.length > 0 &&
                      states.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {stateError ? (
                    <FormHelperText>{stateError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                <FormControl
                  size="small"
                  fullWidth
                  error={cityError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    City{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singlecities}
                    size="small"
                    label="City"
                    onChange={handleCities}
                  >
                    {cities &&
                      cities?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.city}>
                            {item?.city}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {cityError ? (
                    <FormHelperText>{cityError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  display: "flex",
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                  my: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                Job Description
              </Typography>
              <Box>
                <CKEditor
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={handleEditorChange}
                  config={editorConfig}
                  className="ck-editorCustom"
                  onReady={(editor) => {}}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </Box>
              {descriptionError ? (
                <div
                  className="app"
                  style={{
                    fontWeight: 400,
                    color: "#d32f2f",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {descriptionError}
                </div>
              ) : (
                ""
              )}

              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                sx={{
                  "& .MuiOutlinedInput-root": { alignItems: "flex-start" },
                }}
                id="tags-standard"
                value={skills ? skills : []}
                onChange={handleSkillChange}
                onInputChange={(event, newInputValue) => {
                  hanldeSkillInputChange(newInputValue);
                }}
                options={availableOptions?.map((ele) => ele.label)}
                renderTags={renderTags}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Skills"
                    multiline
                    fullWidth
                    rows={4}
                    size="large"
                    placeholder="Enter skills"
                    error={skillsError !== ""}
                    helperText={skillsError}
                    onFocus={(e) => setskillError("")}
                    ref={errorFieldRefs.skills}
                  />
                )}
              />
              <Typography variant="h5">Screening Questions</Typography>
              <Typography sx={{ marginTop: "-15px" }}>
                We recommend adding 3 or more questions. Applicants must answer
                each question.
              </Typography>
              <Box>
                <Typography>
                  <Screeningquestion
                    newerror={newerror}
                    setnewerror={setnewerror}
                    setEmptyQuestionIndices={setEmptyQuestionIndices}
                    emptyQuestionIndices={emptyQuestionIndices}
                    setFormData={setFormData}
                    jobId={jobId}
                    disableflag={disableflag}
                  />
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox checked={isChecked} onChange={handleChange} />
                }
                label="Cover Letter Required"
              />

              <TextField
                id="outlined-multiline-flexible"
                size="small"
                fullWidth
                label="Internal Notes"
                variant="outlined"
                multiline
                value={notes}
                onChange={handlenotes}
              />
            </Box>
            <Typography
              sx={{
                p: { xs: 1, sm: 2, md: 2, lg: 2 },
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                borderTop: "1px solid rgba(0, 0, 0, 0.24)",
              }}
            >
              <Button variant="text" color="inherit" onClick={handleCancelData}>
                {" "}
                Cancel{" "}
              </Button>
              <Button
                variant="contained"
                onClick={handleOpenPost}
                disabled={
                  minexpError === "Minimum Experience required." ||
                  maxexpError ===
                    "Maximum experience cannot be equal to minimum experience."
                    ? true
                    : false
                }
              >
                {" "}
                Preview{" "}
              </Button>
            </Typography>
            <BootstrapDialog
              maxWidth="md"
              onClose={handleClosePost}
              aria-labelledby="customized-dialog-title"
              open={openPost}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Job Preview
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClosePost}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "inherit",
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ backgroundColor: "#F2F8FF" }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: { xs: 1, sm: 2, md: 3, lg: 3 },
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: 1, sm: 2, md: 3, lg: 3 },
                      mb: 2,
                    }}
                    gutterBottom
                  >
                    <Typography>
                      <img
                        src={Logo}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        alt="logo"
                      />
                    </Typography>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography>
                        <b>{jobtitle}</b>
                      </Typography>
                      <Typography>
                        <b>{company_name}</b>
                      </Typography>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ my: 3 }}>
                    <Typography
                      sx={{
                        px: { xs: 1, sm: 2, md: 2, lg: 2 },
                        py: { xs: 1, sm: 2, md: 2, lg: 2 },
                        backgroundColor: "#E6EFF7",
                        borderRadius: 2,
                      }}
                      gutterBottom
                    >
                      <Typography
                        variant="body1"
                        fontSize={"20px"}
                        component="div"
                      >
                        Highlights
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: { xs: 1, sm: 2, md: 2, lg: 15 },
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={"20px"}
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                            my: { xs: 1, sm: 2, md: 2, lg: 2 },
                          }}
                        >
                          <PlaceOutlinedIcon />
                          <Typography
                            variant="body1"
                            fontSize={"20px"}
                            component="div"
                          >
                            {" "}
                            {singlecities},{singlestate}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize={"20px"}
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                            my: { xs: 1, sm: 2, md: 2, lg: 2 },
                          }}
                        >
                          <WorkOutlineOutlinedIcon />
                          <Typography
                            variant="body1"
                            fontSize={"20px"}
                            component="div"
                          >
                            {" "}
                            {minexp ? minexp : ""} {maxexp ? "-" : ""}{" "}
                            {maxexp ? maxexp : ""}{" "}
                            {minexp == 1 && maxexp == ""
                              ? "Year"
                              : minexp || maxexp
                              ? "Years"
                              : ""}
                          </Typography>
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Typography gutterBottom>
                    <h2 className="fw-400">About the Job</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: editorData ? editorData : "NA",
                      }}
                    />
                  </Typography>
                  <div>
                    <h2 className="fw-400">Skills</h2>
                    <Stack spacing={1}>
                      <Stack direction="row" flexWrap={"wrap"} gap={2}>
                        {skills &&
                          skills?.map((item, index) => {
                            return (
                              <Chip
                                key={index}
                                label={item}
                                variant="filled"
                                sx={{ backgroundColor: "rgba(0, 0, 0, 0.87))" }}
                              />
                            );
                          })}
                      </Stack>
                    </Stack>
                  </div>
                  <Typography gutterBottom>
                    <h2 className="fw-400">More Info</h2>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Job Type
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {jobType}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Role
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {singleCategory}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Work Mode
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {singleworkmode}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  size="large"
                  onClick={editFlag ? handleEditJob : handlePostJob}
                >
                  Post
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </FormControl>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={
            snackbarMessage === "Job Created Successfully" ||
            snackbarMessage === "Job Edited Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Createjobdrawer;
